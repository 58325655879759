import React from 'react'
import { Link } from 'gatsby'

import logo from '../images/logo.png'
import heart from '../images/heart.png'

type Props = {
	isHomepage?: boolean
	children: React.ReactNode
}

const Layout = ({ isHomepage, children }: Props) => {
	/*const data = useStaticQuery(graphql`
		query {
			logo: file(relativePath: { eq: "logo.png" }) {
				childImageSharp {
					fixed(width: 600) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)*/

	return (
		<>
			<div className={'body' + (isHomepage ? ' body-homepage' : ' body-page')}>
				<div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column bg-dark">
					<header className="masthead mb-auto">
						<Link to="/" className="logo">
							<img src={logo} />
						</Link>
						{isHomepage && <img className="heart" src={heart} />}
						<div className="inner">
							<nav className="nav nav-masthead justify-content-center">
								<Link
									to="/courses/"
									activeClassName="active"
									className="nav-link"
								>
									Golf courses
								</Link>
								<Link to="/map/" activeClassName="active" className="nav-link">
									Region map
								</Link>
								<Link
									to="/contact/"
									activeClassName="active"
									className="nav-link"
								>
									Order now
								</Link>
							</nav>
						</div>
					</header>
				</div>
			</div>

			<main role="main">{children}</main>

			<footer className="container">
				<p className="text-center lead">
					Do you need more information or have a special request? Send us an
					e-mail at{' '}
					<a href="mailto:info@praguegolfcard.cz">info@praguegolfcard.cz</a>
				</p>
			</footer>
		</>
	)
}

export default Layout
