import React from 'react'
import Helmet, { MetaProps } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import favicon from '../images/favicon.ico'

type Props = {
	title?: string
	description?: string
	lang?: string
	meta?: MetaProps[]
}

const SEO = ({ title, description = '', lang = 'en', meta = [] }: Props) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						titleHome
						titleSuffix
						description
					}
				}
			}
		`
	)

	const metaDescription = description || site.siteMetadata.description

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={title || site.siteMetadata.titleHome}
			titleTemplate={
				title ? `%s · ${site.siteMetadata.titleSuffix}` : undefined
			}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					property: `og:title`,
					content: title
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:type`,
					content: `website`
				}
			].concat(meta)}
			link={[{ rel: 'shortcut icon', href: `${favicon}` }]}
		/>
	)
}

export default SEO
